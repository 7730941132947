<template>
  <div class="floor">
    <div class="floor__title">Этаж</div>
    <div
      class="floor__list d-flex d-lg-block align-items-center justify-content-center"
    >
      <div
        v-for="floor in floorsList"
        :key="floor.value"
        :class="'floor__item ' + floor.size"
      >
        <router-link
          :to="{
            name: 'PlanFloor',
            params: { korp: $route.params.korp, floor: floor.value },
          }"
        >
          {{ floor.value }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    floorsList() {
      const current = parseInt(this.$route.params.floor);
      let array = [];
      let j = 0;
      for (let i = current - 2; i <= current + 2; i++) {
        j++;
        let size = "h3";
        if (j == 1 || j == 5) {
          size = "h5";
        } else if (j == 2 || j == 4) {
          size = "h4";
        }
        array.push({ size: size, value: i });
      }
      array = array.filter((item) => {
        if (
          item.value >= this.$buildings[this.$route.params.korp].floors[0] &&
          item.value <= this.$buildings[this.$route.params.korp].floors[1]
        ) {
          return item;
        }
      });
      if (array.length && array.length < 5) {
        for (let i = 0; i <= 5 - array.length; i++) {
          const nextValue = array[array.length - 1].value + 1;
          if (nextValue <= this.$buildings[this.$route.params.korp].floors[1]) {
            array.push({ size: "h5", value: nextValue });
          }
        }
      }

      return array;
    },
  },
};
</script>

<style scoped>
.floor {
  position: relative;
  z-index: 1;
  width: 56px;
  margin-top: 20px;
  text-align: center;
}

.floor__title {
  margin-bottom: 25px;
}

.floor__item {
  margin: 0 0 30px;
}

.floor__item:last-child {
  margin-bottom: 0;
}

.floor__item.h5 {
  color: inherit;
  font-weight: normal;
  font-size: 24px;
  font-family: "Oranienbaum", "Times New Roman", sans-serif;
  line-height: 1.3;
  opacity: 0.4;
}

.floor__item.h4 {
  opacity: 0.8;
}

.floor__item a {
  color: inherit;
  text-decoration: none;
}

body.-notouch .floor__item a:hover {
  color: #205640;
}
@media (max-width: 991px) {
  .floor {
    width: 100%;
    margin-top: 0;
  }

  .floor__item {
    margin: 0 10px;
  }

  .floor__title {
    font-weight: normal;
  }
}
@media (max-width: 575px) {
  .floor__title {
    margin-bottom: 15px;
  }
}
</style>
