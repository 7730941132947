<template>
  <Head>
    <title>Выбор квартиры {{ $titleEnd }}</title>
    <meta property="og:title" :content="'Выбор квартиры ' + $titleEnd" />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>
  <main class="main mb-5">
    <div class="container">
      <Backlink
        class="-small"
        :to="{ name: 'PlanArchive' }"
        text="Выбор корпуса"
      />
      <!-- <Backlink :to="{ name: 'PlanBuilding', params: { building: this.$route.params.building } }" text="Выбор этажа" /> -->
      <div class="plan-floor">
        <div class="row">
          <div class="col-lg-2">
            <FloorList />
          </div>
          <div class="col-lg-8">
            <FloorPlan class="plan">
              <template #after>
                <Near />
                <div class="north">
                  <Icon icon="north" />
                </div>
              </template>
            </FloorPlan>
          </div>
          <div class="col-lg-2 align-self-end">
            <BuildingsPlan
              class="cursor-zoom"
              @click="
                $vfm.show({
                  component: 'PopupBuildingsPlan',
                  bind: {
                    title: 'Генеральный план',
                  },
                })
              "
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Backlink from "@/components/UI/Backlink.vue";
import Near from "@/components/planFloor/Near.vue";
import FloorList from "@/components/planFloor/FloorList.vue";
import FloorPlan from "@/components/FloorPlan.vue";
import BuildingsPlan from "@/components/planFloor/BuildingsPlan.vue";
import { Head } from "@vueuse/head";

export default {
  components: {
    Head,
    Backlink,
    Near,
    FloorList,
    FloorPlan,
    BuildingsPlan,
  },
};
</script>

<style scoped>
.back-link {
  margin-top: -20px;
}

.plan {
  margin-top: -78px;
}

.north {
  position: absolute;
  top: 0;
  right: -100px;
}
@media (max-width: 1279px) {
  .plan {
    margin-top: 0;
  }

  .buildings {
    margin-left: -30px;
  }
}
@media (max-width: 991px) {
  .plan {
    margin-top: -45px;
  }

  .north {
    top: -40px;
    right: 0;
  }

  .buildings {
    margin: 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .plan {
    margin-top: -25px;
  }

  .north {
    top: -50px;
  }
}
@media (max-width: 575px) {
  .plan {
    margin-top: 0;
    margin-bottom: 80px;
  }

  .buildings {
    display: none;
  }

  .north {
    top: auto;
    right: 50%;
    bottom: -80px;
    margin-right: -50px;
  }
}
</style>
